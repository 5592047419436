<template>
  <div>
    <b-modal ref="modal-packages" id="modalPackages" scrollable hide-footer hide-header>
      <div class="packages-content">
        <span class="packages-modal-title mb-title-packages">Affordable plans. Fit for your needs</span>
        <div class="packages-title-selection-method">
          <span :class="isMonthly ? 'selected-packages-color' : ''" class="cursor-pointer" @click="payMonthly()">Pay monthly</span>
          / 
          <span :class="!isMonthly ? 'selected-packages-color' : ''" class="cursor-pointer" @click="payYearly()">Pay annually</span>
          <img v-if="isMonthly" src="@/assets/images/icon/annually-spark-grey.svg" alt="">
          <img v-if="!isMonthly" src="@/assets/images/icon/annually-spark.svg" alt="">
        </div>
        <div class="list-modal-packages">
          <!-- {{ currentPackage }} -->
          <!-- {{ listPackages }} -->
          <div class="card-modal-packages" v-for="(planPackage, index) in listPackages" :key="index">
            <!-- {{planPackage}} -->
            <span class="modal-title-packages-v3 mb-3">{{ planPackage.name_package }}</span>
            <span class="size-packages-v3 mb-3">{{ planPackage.basic ? niceBytes(planPackage.basic.storage) : '' }}</span>
            <button
              :class="planPackage.basic.storage <= currentPackage.basic.storage ? 'my-3 btn-modal-packages-disabled-v3' : 'my-3 btn-modal-packages-v3'"
              :disabled="planPackage.basic.storage <= currentPackage.basic.storage ? true : false"
              @click="getPackage(planPackage._id)"
            >
              <span>{{ currentPackage.name_package.toLowerCase() == planPackage.name_package.toLowerCase() ? 'Current Plan' : 'Buy Now' }}</span>
            </button>
            <div class="modal-facility-packages-v3">
              <div class="details-packages-v3">
                <img src="@/assets/images/icon/bi_check.svg" alt="">
                <span>{{ planPackage.workgroup.max_workgroup }} workgroups</span>
              </div>
              <div class="details-packages-v3">
                <img src="@/assets/images/icon/bi_check.svg" alt="">
                <span>{{ planPackage.workgroup.max_participants }} workgroup participants</span>
              </div>
              <div class="details-packages-v3">
                <img src="@/assets/images/icon/bi_check.svg" alt="">
                <span>{{ planPackage.max_uploaded }} max. file size per upload</span>
              </div>
              <div class="details-packages-v3">
                <img src="@/assets/images/icon/bi_check.svg" alt="">
                <span>Encryption</span>
              </div>
              <div class="details-packages-v3">
                <img src="@/assets/images/icon/bi_check.svg" alt="">
                <span>Real-time collaboration</span>
              </div>
              <div class="details-packages-v3">
                <img src="@/assets/images/icon/bi_check.svg" alt="">
                <span>File recovery</span>
              </div>
              <div class="details-packages-v3">
                <img src="@/assets/images/icon/bi_check.svg" alt="">
                <span>Desktop and mobile access</span>
              </div>
            </div>
            <div v-if="planPackage.name_package.toLowerCase() == 'free' || planPackage.name_package.toLowerCase() == 'basic'">
              <span class="modal-price-packages-v3">Free</span>
            </div>
            <div v-else>
              <div v-if="!isMonthly">
                <span class="modal-discount-price-packages-v3">${{ planPackage.price_month }}</span>
                <span class="modal-price-packages-v3">${{ divided12(planPackage.price_year) }}<span>/month</span></span>
              </div>
              <span v-else class="modal-price-packages-v3">${{ planPackage.price_month }}<span>/month</span></span>
            </div>
          </div>
        </div>
        <div class="list-modal-enterprise">
          <div v-for="enterprise in enterpriseList" :key="enterprise._id" class="container-class-enterprise">
            <div class="row">
              <div class="col-md-6">
                <div class="d-flex flex-column">
                  <span class="enterprise-main-title mb-3">{{enterprise.name_package}}</span>
                  <span class="mb-3 enterprise-text-storage">{{niceBytes(enterprise.storage)}}</span>
                  <span class="mb-3 enterprise-text-desc">Share your {{niceBytes(enterprise.storage)}} storage with the people within your organization or enterprise.</span>
                  <!-- <span class="mb-3 enterprise-text-price">${{!isMonthly ? enterprise.price_year : enterprise.price_month}}<span>/{{!isMonthly ? 'year' : 'month'}}</span></span> -->
                  <span class="mb-3 enterprise-text-price">${{enterprise.price_month}}<span>/month</span></span>
                  <button :disabled="currentEnterprise != null" @click="getPackageEnterprise(enterprise._id)" class="mt-3" :class="currentEnterprise == null ? 'btn-get-enterprise':'btn-disabled-get-enterprise'">Buy Now</button>
                </div>
              </div>    
              <div class="col-md-6">
                <div class="d-flex flex-column enterprise-facility">
                  <span class="mb-4">Everything in Premium, plus:</span>
                  <div class="d-flex align-items-center enterprise-facility mb-4">
                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                    <span>Free for up to {{enterprise.free_user}} seats, then ${{enterprise.price_user}}/seat/month</span>
                  </div>
                  <div class="d-flex align-items-center enterprise-facility mb-4">
                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                    <span>Unlimited workgroups</span>
                  </div>
                  <div class="d-flex align-items-center enterprise-facility mb-4">
                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                    <span>Unlimited workgroup participants</span>
                  </div>
                  <div class="d-flex align-items-center enterprise-facility mb-4">
                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                    <span>Storage sharing and control</span>
                  </div>
                  <div class="d-flex align-items-center enterprise-facility mb-4">
                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                    <span>Admin dashboard</span>
                  </div>
                  <div class="d-flex align-items-center enterprise-facility mb-4">
                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                    <span>Customized logo and colors</span>
                  </div>
                  <div class="d-flex align-items-center enterprise-facility">
                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                    <span>Custom domain</span>
                  </div>
                </div>
              </div>    
            </div>
          </div>
        </div>
        <span class="cancel-modal-packages cursor-pointer" @click="close()">Cancel</span>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data(){
    return {
      isMonthly: true,
      suscribe: true
    }
  },
  computed: {
    listPackages: {
      cache: false,
      get() {
        const data = this.$store.getters["packages/allPackages"]
        data.map(el => {
          // el.basic.storage = this.niceBytes(el.basic.storage)
          if (el.basic !== undefined) {
            if (el.basic.max_upload !== 'unlimited') {
              el.max_uploaded = this.niceBytes(el.basic.max_upload)
            } else {
              el.max_uploaded = this.upperCase(el.basic.max_upload)
            }
          }
        })
        return this.sortPackages(data)
      },
      set(newValue) {
        this.$store.commit("packages/SET_PACKAGES", newValue)
      }
    },
    enterpriseList() {
      return this.$store.getters["enterprise/listEnterprise"]
    },
    currentPackage() {
      return this.$store.getters["user/package"]
    },
    currentEnterprise() {
      return this.$store.getters['enterprise/myEnterprise']
    }
  },
  methods: {
    payMonthly() {
      this.isMonthly = true
    },
    payYearly() {
      this.isMonthly = false
    },
    close() {
      this.$bvModal.hide('modalPackages')
    },
    getPackage(id) {
      this.$store.commit('packages/SET_SELECTED_PRICE', this.isMonthly)
      this.$store.dispatch('packages/getPackageById', id)
      .then((res) => {
        this.$bvModal.hide('modalPackages')
        this.$bvModal.show('modal-checkout-v3')
      })
    },
    getPackageEnterprise(id) {
      this.$store.commit('packages/SET_SELECTED_PRICE', true)
      this.$store.dispatch('packages/getPackageById', id)
      .then((res) => {
        this.$bvModal.hide('modalPackages')
        this.$bvModal.show('modal-checkout-v3')
      })
    },
    niceBytes(bytes) {
      var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes == 0) return 'n/a';
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      if (i == 0) return bytes + ' ' + sizes[i];
      return (bytes / Math.pow(1024, i)).toFixed() + ' ' + sizes[i];
    },
    divided12(value) {
      var price = value / 12
      return price.toFixed(2)
    },
    upperCase(str) {
      let strIndex = str.split('')
      let arr = [0]
      for(let i = 0; i < arr.length; i++){
        if(strIndex[arr[i]]){
          strIndex[arr[i]] = strIndex[arr[i]].toUpperCase()
        }
      }
      return strIndex.join('')
    },
    sortPackages(value) {
      return value.slice().sort(function(a, b) {return a.basic.storage - b.basic.storage})
    }
  }
}
</script>

<style>

</style>